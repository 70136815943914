export const BU = 5

export const isMobile = window.innerWidth < 600

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const buttonActionsMap = {
  REFRESH: 'getInitialProps',
  UPLOAD_ATTACHMENTS: 'openUploadModal',
  ADD_NOTES: 'openNotesModal',
  SEND_SMS: 'openSmsModal',
  MANUAL_SEND_CONDITIONS: 'openLinkModal',
  VERIFY_OTP: 'openOtpModal',
  SEND_CONDITIONS_COPY: 'openEmailModal',
  MANUAL_SEND_CONDITIONS_COPY: 'openAttachmentsModal',
  RETURN_TO_LIST: 'navigateToList',
}

const ECOSIGN_STATUS_IMPORTED = 'IMPORTED'
const ECOSIGN_STATUS_SEND_CONDITIONS_DONE = 'SEND_CONDITIONS_DONE'
const ECOSIGN_STATUS_SEND_CONDITIONS_FAIL = 'SEND_CONDITIONS_FAIL'
const ECOSIGN_STATUS_UPLOAD_ATTACHMENTS_DONE = 'UPLOAD_ATTACHMENTS_DONE'
const ECOSIGN_STATUS_UPLOAD_ATTACHMENTS_FAIL = 'UPLOAD_ATTACHMENTS_FAIL'
const ECOSIGN_STATUS_ADD_NOTES_DONE = 'ADD_NOTES_DONE'
const ECOSIGN_STATUS_ADD_NOTES_FAIL = 'ADD_NOTES_FAIL'
const ECOSIGN_STATUS_CONDITIONS_ACCEPTED = 'CONDITIONS_ACCEPTED'
const ECOSIGN_STATUS_VERIFY_OTP_DONE = 'VERIFY_OTP_DONE'
const ECOSIGN_STATUS_VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL'
const ECOSIGN_STATUS_SEND_CONDITIONS_COPY_FAIL = 'SEND_CONDITIONS_COPY_FAIL'
const ECOSIGN_STATUS_COMPLETED = 'COMPLETED'
const ECOSIGN_STATUS_CANCELLED = 'CANCELLED'

const ECOSIGN_BADGE_STATUS_SUCCESS = 'success'
const ECOSIGN_BADGE_STATUS_PROCESSING = 'processing'
const ECOSIGN_BADGE_STATUS_ERROR = 'error'

export const ecoSignStatusesMap = {
  [ECOSIGN_STATUS_IMPORTED]: ECOSIGN_BADGE_STATUS_PROCESSING,
  [ECOSIGN_STATUS_UPLOAD_ATTACHMENTS_DONE]: ECOSIGN_BADGE_STATUS_PROCESSING,
  [ECOSIGN_STATUS_UPLOAD_ATTACHMENTS_FAIL]: ECOSIGN_BADGE_STATUS_ERROR,
  [ECOSIGN_STATUS_ADD_NOTES_DONE]: ECOSIGN_BADGE_STATUS_PROCESSING,
  [ECOSIGN_STATUS_ADD_NOTES_FAIL]: ECOSIGN_BADGE_STATUS_ERROR,
  [ECOSIGN_STATUS_SEND_CONDITIONS_DONE]: ECOSIGN_BADGE_STATUS_PROCESSING,
  [ECOSIGN_STATUS_SEND_CONDITIONS_FAIL]: ECOSIGN_BADGE_STATUS_ERROR,
  [ECOSIGN_STATUS_CONDITIONS_ACCEPTED]: ECOSIGN_BADGE_STATUS_PROCESSING,
  [ECOSIGN_STATUS_VERIFY_OTP_DONE]: ECOSIGN_BADGE_STATUS_PROCESSING,
  [ECOSIGN_STATUS_VERIFY_OTP_FAIL]: ECOSIGN_BADGE_STATUS_ERROR,
  [ECOSIGN_STATUS_SEND_CONDITIONS_COPY_FAIL]: ECOSIGN_BADGE_STATUS_ERROR,
  [ECOSIGN_STATUS_COMPLETED]: ECOSIGN_BADGE_STATUS_SUCCESS,
  [ECOSIGN_STATUS_CANCELLED]: ECOSIGN_BADGE_STATUS_ERROR,
}
